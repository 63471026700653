import React from "react"
import Layout from "../../components/Layout/LayoutNewYork"
import PageHeader from "../../components/shared/PageHeader"
import styled from "styled-components";
import {Container, Row, Col} from "reactstrap";
import VideoBlock from "../../components/CastAlbum/VideoBlock";
import arrowDown from "../../images/CastAlbum/arrow-down.svg"
import tiktokLogo from "../../images/CastAlbum/tiktok.svg"
import {graphql, StaticQuery} from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {media} from "utils/Media"
import StyledButton from "../../components/shared/StyledButton"
import SignUp from "../../components/Footer/SignUp";
import { Helmet } from "react-helmet";

const CdSection = styled.div`
    background-color: ${props => props.theme.colors.black};
    text-align: center;
    padding-bottom: 2rem;
    iframe {
        height: 50rem;
    }
    @media ${media.lg} {
        padding-top: 1rem;
        padding-bottom: 3rem;
        text-align: left;
    }
    
    .cdImg {
        width: 533px;
        margin: 0 auto;
        padding-bottom: 2rem;
        max-width: 100%;
        
        @media ${media.lg} {
            padding-bottom: 0;
            width: 100%;
        }
    }
    
    .content {
        max-width: 533px;
        margin: 0 auto;
        
        p {
            margin-bottom: 2rem;
            line-height: 125%;
            
            @media ${media.md} {
                font-size: 30px;
            }
            
            @media ${media.lg} {
                margin-bottom: 1rem;
                font-size: 20px;
            }
            
            @media ${media.xl} {
                font-size: 22px;
            }
        }
    }
    
    @media ${media.lg} {
        width: 100%;
    }
    
    p {
        color: ${props => props.theme.colors.white};
    }
    
    .logos {
        padding-bottom: 2rem;
        
        @media ${media.lg} {
            padding-bottom: 0;
        }
        .spotifyLogo {
            display: block;
            margin: 2rem auto 0;
            width: 113px;
            
            @media ${media.md} {
                width: 229px;
            }
            
            @media ${media.lg} {
                display: inline-block;
                margin: 0 auto;
                width: 125px;
            }
            
            @media ${media.xl} {
                width: 155px;
            }
        }
        .appleLogo {
            width: 128px;
            display: block;
            margin: 1rem auto 0;
            
            @media ${media.md} {
                width: 259px;
            }
            
            @media ${media.lg} {
                display: inline-block;
                margin: 0 20px 0 0;
                width: 140px;
            }
            
            @media ${media.xl} {
                width: 170px;
            }
        }
        .amazonLogo {
            width: 133px;
            display: block;
            margin: 1rem auto 0;
            
            @media ${media.md} {
                width: 269px;
            }
            
            @media ${media.lg} {
                display: inline-block;
                margin-top: 0 10px 0 -10px;
                width: 163px;
            }
            
            @media ${media.xl} {
                width: 183px;
            }
        }
    }
    
    .arrowDown {
        display: none;
        
        @media ${media.lg} {
            margin: 3rem auto 0;
            display: block;
        }
    }
`

const VideoSection = styled.div`
`

const SignUpSection = styled.div`
`

const CastAlbum = (props) => {
    return (
        <Layout title="Cast Album | New York | Back to the Future the Musical"
                description="Cast Album | The official website for Back to the Future The Musical. Now playing on Broadway at the Winter Garden Theatre in New York."
                booking="https://www.telecharge.com/Broadway/Back-to-the-Future-the-Musical/Overview?AID=BWY001390400&utm_source=show_site&utm_campaign=Back-to-the-Future-the-MusicalSS&utm_medium=web"
        >
            <Helmet>
            <script
              id="satisfiScript"
              src="https://chat.satis.fi/popup/embedder?popupId=17903"
            ></script>
          </Helmet>
            <PageHeader
                newBg
                city="new-york"
                title="GREAT SCOTT! ORIGINAL CAST RECORDING AVAILABLE NOW"
            />
            <CdSection>
                <Container>
                    <Row>
                        <Col xs={12} lg={6} className="my-auto">
                            <div className="cdImg">
                                <a href="https://sonysoundtracks.lnk.to/BackInTimeWE" target="_blank"
                                   rel=" noopener">
                                    <GatsbyImage image={getImage(props.data.cdImage)}

                                        alt=""
                                        title=""
                                        className="img-fluid"
                                    />
                                </a>
                            </div>
                        </Col>
                        <Col xs={12} lg={6} className="my-auto">
                            <div className="content">
                                <p>Sony Music Masterworks is thrilled to partner with Colin Ingram to release the <strong>ORIGINAL LONDON CAST RECORDING</strong> of <strong><em>BACK TO THE FUTURE: THE MUSICAL</em></strong>, featuring new music by multi-Grammy winners Alan Silvestri and Glen Ballard alongside classic hits from the movie. Available to stream and buy now.</p>
                                <StyledButton href="https://soundtracks.lnk.to/BTTFAlbumIN" target="_blank"
                                              rel=" noopener">
                                    STREAM/BUY NOW
                                </StyledButton>
                                <p className="mt-4 mb-4">Vinyl edition now on sale. When this baby hits 33rpm, you’re gonna hear some serious… entertainment! Get your Back to the Future The Musical fix, 1955-style.</p>
                                <StyledButton href="https://www.amazon.co.uk/Back-Future-Musical-Original-Cast/dp/B0B4D954HM/ref=sr_1_1?crid=2MFU2C8Z7N5ZU&keywords=back+to+the+future+vinyl+musical&qid=1660905939&sprefix=back+to+the+future+vinyl+musical%2Caps%2C175&sr=8-1&tag=sonymusiccommercial-21&ie=UTF8&linkCode=as2&ascsubtag=ac8eb77c644abe863bd351f1928e54b6&ref=dmm_acq_soc_gb_u_lfire_lp_x_ac8eb77c644abe863bd351f1928e54b6" target="_blank"
                                              rel=" noopener">
                                    BUY NOW
                                </StyledButton>
                            </div>
                        </Col>
                    </Row>
                    <iframe
                        style={{
                            width: "100%",
                            minHeight: "1220px",
                            marginTop: "2rem"
                        }}
                        height="100%" width="100%"
                        data-lnk-widget
                        src="https://soundtracks.lnk.to/BTTFAlbumWE"
                        frameBorder="0"
                        allowTransparency="true">
                    </iframe>
                    <img className="arrowDown" src={arrowDown} alt="" title=""/>
                </Container>
            </CdSection>
            <VideoSection>
                <VideoBlock/>
            </VideoSection>
        </Layout>
    )
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                cdImage: file(relativePath: { eq: "CastAlbum/BttF-Pre-Order-Now.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 530)
                    }
                }
                phone: file(relativePath: { eq: "CastAlbum/iphone-marty.png" }) {
                    childImageSharp {
                       gatsbyImageData(width: 600)
                    }
                }
            }
		`}
        render={data => (
            <CastAlbum data={data}/>
        )}
    />
)
